import React, { useEffect, useState } from "react"

import style from "./vdp.module.scss"

import iconArrow from "../img/icon-arrow.svg"

const fields = [
  { label: "Stock #: ", value: "StockNumber" },
  { label: "VIN #: ", value: "VIN" },
  { label: "Year: ", value: "Year" },
  { label: "Make: ", value: "Make" },
  { label: "Model: ", value: "Model" },
  { label: "Trim: ", value: "Trim" },
  { label: "Drive Train: ", value: "Drivetrain" },
  { label: "Body: ", value: "BodyType" },
  { label: "Doors: ", value: "Doors" },
  { label: "Mileage: ", value: "Mileage" },
  // { label: "Engine Cylinders: ", value: "Cylinders" },
  // { label: "Transmission: ", value: "Transmission" },
  { label: "Interior Color: ", value: "InteriorColor" },
  { label: "Exterior Color: ", value: "ExteriorColor" }
]

export default function VDPDescription({ vehicle }) {
  const { Comments } = vehicle.VehicleInfo;
  const [currentTab, setTab] = useState(1);

  const toggleTab = (idx) => {
    if (currentTab === idx) {
      setTab(-1);
    } else {
      setTab(idx);
    }
  };

  const tabsContent = [
    () => null, // Commented out the content for the "Description" tab
    // () => (
    //   <li className={style["tabs-content__item"]}>
    //     <div className={style["features"]}>
    //       <p className={style["features__description"]}>
    //         <span dangerouslySetInnerHTML={{__html:Comments}}/>
    //       </p>
    //     </div>
    //   </li>
    // ),
    () => (
      <li className={style["tabs-content__item"]}>
        <div className={style["option-list"]}>
          {fields.map((field, index) => (
            <div className={style["option-list__item"]} key={index}>
              <span className={style["option-list__option"]}>
                {field.label}
              </span>
              <span className={style["option-list__value"]}>
                {field.value === "Mileage" && vehicle.VehicleInfo.hasOwnProperty(field.value)
                  ? vehicle.VehicleInfo[field.value].toLocaleString() // Format mileage with commas
                  : vehicle.VehicleInfo.hasOwnProperty(field.value)
                  ? vehicle.VehicleInfo[field.value]
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </li>
    )
    ,
  ];

  const tabs = ["Description", "Options"];

  return (
    <section className={style["tabs-wrap"]}>
      <ul className={style["tabs"]}>
        {tabs.map((tab, idx) => (
          tab === "Options" && (
            <>
              <li className={style["tabs__item"]}>
                <button
                  className={`${style["tabs__btn"]} ${
                    currentTab === idx && style["tabs__btn_active"]
                  }`}
                  onClick={() => toggleTab(idx)}
                >
                  <span className={style["tabs__btn-text"]}>{tab}</span>
                  <img
                    className={`${style["tabs__btn-img"]} ${
                      currentTab === idx && style["caret-active"]
                    }`}
                    src={iconArrow}
                    alt=""
                  />
                </button>
              </li>
              <div className={style["tabs-resp"]}>
                {currentTab === idx && tabsContent[currentTab]()}
              </div>
            </>
          )
        ))}
      </ul>
      <ul className={style["tabs-content"]}>
        {currentTab > -1 && tabsContent[currentTab]()}
      </ul>
    </section>
  );
}